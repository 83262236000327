import Layout from '../components/Layout';
import React, { FC } from 'react';

const Thanks: FC = () => {
  return (
    <Layout>
      <h2></h2>
      <p>
        Nicola Hahne
        <br />
        Fabrikstrasse 2
        <br />
        4123 Allschwil
        <br />
        Schweiz
      </p>
      <p>
        E-Mail:
        <br />
        nic-hahne@hotmail.com
      </p>
      <h2>Urheberrechte</h2>
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf
        dieser Website, gehören ausschliesslich <strong>Nicola Hahne</strong> oder den speziell
        genannten Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche
        Zustimmung des Urheberrechtsträgers im Voraus einzuholen.
      </p>
      <br />
      Quelle:{' '}
      <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener noreferrer">
        SwissAnwalt
      </a>
    </Layout>
  );
};

export default Thanks;
